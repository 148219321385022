import React,{useEffect} from "react";
import Layout from '../../components/Layout'
import { FaAngleRight } from "@react-icons/all-files/fa/FaAngleRight";
import { Link,graphql,navigate } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import SocialConnect from "../../components/ConnectSocial";
import Accordion from "../../components/Accordion";

const ComponentName = ({ data }) => {

  const daa = data.strapiArticles
  return (
    <Layout title={data.strapiArticles.title}>
   
      <div className="about">
      <BackgroundImage
        backgroundColor={`#0080FF`}
        fluid={daa.image.localFile.childImageSharp.fluid}
         className="hero">
         <div className="overlay">
           <h1>{daa.title}</h1>
          </div>
          </BackgroundImage>

        <div className="ssbody wrapper_max">
    
          <div className="content">
          {/* {daa.hindiTitle&&<div className="hindiLink" style={{cursor:'pointer'}} onClick={()=>{  navigate('/blog/hindi/'+daa.slug)}} data-nosnippet>Read in Hindi</div>} */}
            <div className='page-body' dangerouslySetInnerHTML={{__html: daa.body}} />             
              <Accordion data={daa.section}/>
            <SocialConnect share={true}/>
          </div>
        </div>
      </div>


    </Layout>
  );
};

export const query = graphql`
  query ArticlesQuery($slug: String!) {
    strapiArticles(slug: { eq: $slug }) {
      id
      slug
      title
      body
      hindiTitle
      section{
        body
        title
      }
      image {
        localFile {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1900) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;

export default ComponentName;
